.c-wrapper {
    background: white;
}

.c-container {
    justify-content: space-around;
    gap: 1rem;
}

.c-container>img {
    width: 9rem;
}